<template>
  <div class="text-left form-billing-info" :key="$store.state.updateTimestamp">
    <v-form
      ref="form"
      class="pkmn-form"
      @keyup.native.enter.prevent="submitForm"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="inputOrderRef"
            class="form__order-ref pkmn-input"
            :label="`${$t('group_order_ref')} (${$t('g_optional')})`"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-select
            class="pkmn-input pkmn-input--has-notice"
            v-model="formData.current_payment_method"
            :items="paymentOptions"
            :label="$t('settings_billing_payment_method_select')"
            :hint="invoiceFeeNotice"
            persistent-hint
            :rules="[rules.required]"
            @input="changePaymentMethod"
          ></v-select>
        </v-col>
      </v-row>

      <div v-if="formData.current_payment_method === 'card'">
        <v-row dense>
          <v-col cols="12">
            <braintree-input
              ref="inputCardNumber"
              field="card-number"
              :label="$t('settings_billing_card_number')"
              :showLoader="!isBraintreeLoaded"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <braintree-input
              ref="inputCardExp"
              field="card-expiry"
              :label="$t('settings_billing_card_expiry')"
              :showLoader="!isBraintreeLoaded"
            />
          </v-col>
          <v-col cols="6">
            <braintree-input
              ref="inputCardCvc"
              field="cvv"
              :label="$t('settings_billing_card_cvc')"
              :showLoader="!isBraintreeLoaded"
            />
          </v-col>
        </v-row>
      </div>

      <div v-else-if="isInvoiceSelected">
        <v-row dense v-if="formData.current_payment_method === 'email_invoice'">
          <v-col cols="12">
            <v-text-field
              v-model="formData.email"
              class="pkmn-input"
              :label="$t('g_email')"
              validate-on-blur
              :rules="[rules.required, rules.email]"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <div v-if="formData.current_payment_method === 'e_invoice'">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="formData.edi_number"
                class="pkmn-input"
                :label="$t('settings_e_invoice_edi_code')"
                validate-on-blur
                :rules="[rules.required]"
                @blur="
                  formData.edi_number = helpers.keepAlphanumeric(
                    formData.edi_number
                  )
                "
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="formData.edi_process_name"
                class="pkmn-input"
                :label="$t('settings_e_invoice_edi_processor')"
                validate-on-blur
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="formData.edi_process_address"
                class="pkmn-input"
                :label="$t('settings_e_invoice_edi_processor_address')"
                validate-on-blur
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>

        <div class="paper-invoice" v-if="formData.current_payment_method === 'paper_invoice'">
          <v-row dense>
            <v-col cols="12">
              <v-checkbox
                class="pkmn-checkbox"
                v-model="formData.billing_use_customer_address"
                @change="fillBillingAddress"
              >
                <template v-slot:label>
                  {{ $t('settings_invoice_use_company_address') }}
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="formData.address"
                class="pkmn-input"
                :label="$t('g_address')"
                :disabled="isUsingCompanyAddressForBilling"
                :rules="[rules.required, addressRule]"
                validate-on-blur
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6">
              <v-text-field
                v-model="formData.city"
                class="pkmn-input pkmn-input--inline"
                :label="$t('g_city')"
                :disabled="isUsingCompanyAddressForBilling"
                :rules="[rules.required]"
                validate-on-blur
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="formData.postal_code"
                class="pkmn-input"
                :label="$t('g_postal_code')"
                :disabled="isUsingCompanyAddressForBilling"
                :rules="[rules.required]"
                validate-on-blur
                @blur="
                  formData.postal_code = helpers.keepAlphanumeric(
                    formData.postal_code
                  )
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>

      <v-row dense v-if="isTestEnv">
        <v-col cols="12">
          <v-checkbox class="pkmn-checkbox" v-model="isTesting2099">
            <template v-slot:label> Test error 2099 (test env. only) </template>
          </v-checkbox>
        </v-col>
      </v-row>

      <v-row v-if="errorMessage">
        <v-col cols="12" class="text-right">
          <p class="pkmn-form__error" v-html="errorMessage"></p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import getSymbolFromCurrency from 'currency-symbol-map'

import api from '@/helpers/api'
import braintree from '@/helpers/braintree'
import helpers from '@/helpers'
import rules from '@/helpers/validation'
import signupHelpers from '@/components/Signup/signup_helpers'
import paymentHelpers from '@/helpers/payment'

import BraintreeInput from '@/components/Settings/BraintreeInput'

export default {
  name: 'FormBillingInfo',
  props: ['data', 'groupAccountId', 'orderRef'],
  components: { BraintreeInput },
  data() {
    return {
      formData: {
        current_payment_method: null
      },
      inputOrderRef: '', // have to do this as v-text-field does not recognize this in formData
      availablePaymentMethods: {}, // store payment methods inputted to the associated group, including the current one and unselected ones
      isBraintreeLoaded: false,
      braintreeInstances: null,
      rules: rules,
      errorMessage: null,
      helpers: helpers,
      invoiceFee: null,
      isTesting2099: false,
      isRetried2099: false
    }
  },
  computed: {
    companyData() {
      return this.$store.state.companyData // use in case paper invoice uses company address
    },
    paymentOptions() {
      const options = this.$store.state.paymentOptions

      if (!options) {
        this.$store.dispatch('setPageReadyStatus', false)
        return []
      }

      this.$store.dispatch('setPageReadyStatus', true)
      return options.map((method) => {
        return {
          value: method,
          text: this.$t(`settings_billing_${method}`)
        }
      })
    },
    isInvoiceSelected() {
      const method = this.formData.current_payment_method
      return method && method !== 'card'
    },
    /**
     * Check if any of the invoice methods is selected
     */
    invoiceFeeNotice() {
      if (!this.isInvoiceSelected || !this.invoiceFee) {
        return ''
      }

      return this.$t('settings_invoice_fee_notice', {
        feeAmount: this.invoiceFee.amount,
        currency: getSymbolFromCurrency(this.invoiceFee.currency)
      })
    },
    isUsingCompanyAddressForBilling() {
      // have to do this as backend return string "0" and "1" as true or false
      const formValue = this.formData.billing_use_customer_address
      return typeof formValue === 'boolean'
        ? formValue
        : Boolean(parseInt(formValue))
    },
    isTestEnv() {
      return process.env.VUE_APP_ENV === 'test'
    }
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.$emit('changeSubmittableState', this.getSubmittableState())
      }
    },
    isBraintreeLoaded() {
      this.$emit('changeSubmittableState', this.getSubmittableState())
    }
  },
  methods: {
    addressRule: (value) => {
      return (value || "").length >= 4 || `Address must have at least 4 characters length`
    },
    getSubmittableState() {
      if (!this.formData.current_payment_method) {
        return false
      }

      if (
        this.formData.current_payment_method === 'card' &&
        !this.isBraintreeLoaded
      ) {
        return false
      }

      return signupHelpers.isBillingInfoFilled(this.formData)
    },
    validateBraintreeForm() {
      const hostedFieldsState = this.braintree.instances.hostedFields.getState()
      let isFormValid = true
      Object.keys(hostedFieldsState.fields).forEach((field) => {
        if (!hostedFieldsState.fields[field].isValid) {
          hostedFieldsState.fields[field].container.classList.add(
            'braintree-hosted-fields-invalid'
          )
          isFormValid = false
        }
      })
      return isFormValid
    },
    /**
     * Autofill billing address with company address if checkbox is selected
     */
    fillBillingAddress() {
      if (this.isUsingCompanyAddressForBilling) {
        this.$set(this.formData, 'address', this.companyData.address)
        this.$set(this.formData, 'city', this.companyData.city)
        this.$set(this.formData, 'postal_code', this.companyData.post_code)
      }
    },
    /**
     * [Signup flow]
     * Redirect user to previous step
     */
    goToPreviousStep() {
      helpers.sendTrackingEvent('ONBOARDING', 'click_back_to_company_info')
      this.$emit('proceed', 1)
    },
    /**
     * [Signup flow]
     * Redirect user to next step
     * Update 10.11.2020: This step completes the setup flow
     */
    goToNextStep() {
      this.$emit('complete')
    },
    /**
     * [Settings]
     * Close form and reset all unsaved data
     */
    closeForm() {
      this.$emit('close')
    },
    /**
     * Update the form with corresponding payment method
     */
    async changePaymentMethod(selectedMethod) {
      // reset form data
      this.formData = {
        current_payment_method: selectedMethod
      }

      this.invoiceFee = null
      // set the group id to check invoice fee. If no group account id (the case of creating new group, use company account id instead)
      const accountIdForInvoiceFeeCheck =
        // this.groupAccountId || // UPDATE 13.09.2022: don't use group id to fetch invoice fee
        this.$store.state.companyData.account_id

      let paymentInfo
      switch (selectedMethod) {
        case 'card':
          braintree.init(this, this.data)
          break
        case 'email_invoice':
          // now support only one invoice object in each type (1 email invoice, 1 paper invoice, 1 e invoice).
          // if invoice exists, billing info form sets it into active payment method and modifies data if there are changes.
          // if invoice does not exist, billing info form add it as new payment method to the group
          var emailInvoices = this.availablePaymentMethods.email_invoices
          if (emailInvoices && emailInvoices.length > 0) {
            paymentInfo = this.availablePaymentMethods.email_invoices[0]
            this.formData.id = paymentInfo.id
            this.formData.email = paymentInfo.email
          }
          this.invoiceFee = await paymentHelpers.fetchInvoiceSendingFee(
            'COMPANY_EMAIL_INVOICE_FEE',
            accountIdForInvoiceFeeCheck
          )
          break
        case 'e_invoice':
          // see comment in email_invoice
          var eInvoices = this.availablePaymentMethods.electronic_invoices
          if (eInvoices && eInvoices.length > 0) {
            paymentInfo = this.availablePaymentMethods.electronic_invoices[0]
            this.formData.id = paymentInfo.id
            this.formData.edi_number = paymentInfo.edi_number
            this.formData.edi_process_name = paymentInfo.edi_process_name
            this.formData.edi_process_address = paymentInfo.edi_process_address
          }
          this.invoiceFee = await paymentHelpers.fetchInvoiceSendingFee(
            'COMPANY_E_INVOICE_FEE',
            accountIdForInvoiceFeeCheck
          )
          break
        case 'paper_invoice':
          // see comment in email_invoice
          var paperInvoices = this.availablePaymentMethods.paper_invoices
          if (paperInvoices && paperInvoices.length > 0) {
            paymentInfo = this.availablePaymentMethods.paper_invoices[0]
            this.formData.id = paymentInfo.id
            this.formData.address = paymentInfo.address
            this.formData.city = paymentInfo.city
            this.formData.postal_code = paymentInfo.postal_code
          }
          this.invoiceFee = await paymentHelpers.fetchInvoiceSendingFee(
            'COMPANY_PAPER_INVOICE_FEE',
            accountIdForInvoiceFeeCheck
          )
          break
        default:
          break
      }

      this.$forceUpdate()
    },
    /**
     * Validate form
     * Submit if validation passed
     */
    submitForm() {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.formData.current_payment_method === 'card') {
        if (!this.validateBraintreeForm()) {
          this.errorMessage = this.$t('settings_billing_invalid_card')
          this.$emit('error')
          return
        }
        // validate braintree form
        braintree.submit(this, this.submitCard)
      } else {
        this.submitInvoice()
      }
    },
    /**
     * Call when selected payment method is card
     *
     * special case: when GET token/braintree endpoint return three_ds_required = false
     * but user got error 2099 after submit
     * retry by forcing 3ds verification screen so user can see the 3ds form
     */
    async submitCard(data) {
      let testErrorCodeParam = ''
      if (this.isTesting2099 && !this.isRetried2099) {
        // manual trigger error 2099 from backend for test env. if test mode selected
        testErrorCodeParam = '?code=2099'
      }

      const apiSettings = {
        method: 'post',
        service: 'billing',
        url: 'b2b/payment_methods/payment_cards' + testErrorCodeParam,
        data: {
          account_id: this.groupAccountId,
          country_code:
            this.$store.state.companyData.country.code.toLowerCase(),
          nonce: data.braintree_nonce
        }
      }

      try {
        const response = await api.promise(apiSettings)
        const apmId = response.payment_card.account_payment_method_id
        await this.setPaymentMethodAttributes(apmId)
        this.$emit('submit')
      } catch (error) {
        // handle general 401 error
        api.handleError(error, apiSettings)

        const firstError = error[0] || null
        // if error code from backend is 2099, retry the card once
        if (firstError?.field === '2099' && !this.isRetried2099) {
          this.isRetried2099 = true
          await braintree.submit(this, this.submitCard, { retryWith3ds: true })
          return
        }

        // display the error for other cases
        this.$store.dispatch(
          'setSystemMessage',
          firstError ? firstError.message : this.$t('g_internal_error')
        )
        // emit this to hide loading overlay of parent component
        this.$emit('error')
      }
    },
    /**
     * Call when selected payment method is e-invoice, email invoice or paper invoice
     */
    submitInvoice() {
      const data = {
        account_id: this.groupAccountId,
        country_code: this.$store.state.companyData.country.code.toLowerCase()
      }

      if (this.formData.current_payment_method === 'paper_invoice') {
        data.address = this.formData.address
        data.city = this.formData.city
        data.postal_code = this.formData.postal_code
      }

      if (this.formData.current_payment_method === 'e_invoice') {
        data.edi_number = this.formData.edi_number
        data.edi_process_name = this.formData.edi_process_name
        data.edi_process_address = this.formData.edi_process_address
      }

      if (this.formData.current_payment_method === 'email_invoice') {
        data.email = this.formData.email
      }

      let url = 'payment_methods/'
      switch (this.formData.current_payment_method) {
        case 'email_invoice':
          url += 'email_invoicing'
          break
        case 'e_invoice':
          url += 'electronic_invoicing'
          break
        default:
          url += 'paper_invoicing'
          break
      }

      // if there is already an id (payment method's id) in formData, it means editing
      if (this.formData.id) {
        url += `/${this.formData.id}`
      }

      const apiSettings = {
        method: this.formData.id ? 'put' : 'post',
        service: 'billing',
        url: url,
        data: data
      }

      const onSuccess = (response) => {
        try {
          var apmId = Object.values(response)[0].account_payment_method_id
          this.setPaymentMethodAttributes(apmId)
            .then((response) => {
              api.handleSuccess(response)
            })
            .catch((error) => {
              api.handleError(error, apiSettings)
            })
            .finally(async () => {
              this.$emit('submit')
            })
        } catch (exception) {
          this.$store.dispatch('setSystemMessage', this.$t('g_internal_error'))
        }
      }

      const onFailed = (response) => {
        this.$emit('error', response)
      }

      api.call(apiSettings, onSuccess, onFailed)
    },
    /**
     * Call extra endpoint to set the updated payment method as default method of the group
     * Also, add order ref. if available
     */
    setPaymentMethodAttributes(accountPaymentMethodId) {
      const data = {
        is_disabled: false, // required by backend
        set_default: true // always set newly edited method as the active one
      }

      if (this.inputOrderRef) {
        data.order_ref = this.inputOrderRef
      }

      const apiSettings = {
        method: 'post',
        service: 'billing',
        url: `b2b/account_payment_methods/${accountPaymentMethodId}`,
        data: data
      }

      return api.promise(apiSettings)
    }
  },
  mounted() {
    // prefill payment data if it is used for editing
    this.$nextTick(() => {
      if (!this.data) {
        return
      }

      this.availablePaymentMethods = this.data
      this.inputOrderRef = this.orderRef

      // check if the group has an active payment method selected
      if (this.data.default_account_payment_method_id) {
        // fill the form with current payment method
        this.formData = paymentHelpers.getActivePaymentMethod(this.data)
        this.formData.current_payment_method = this.formData.method
        this.changePaymentMethod(this.formData.method)
      } else {
        this.formData.current_payment_method = null
      }
    })
  }
}
</script>

<style lang="scss">
@import '@/style/common';

.pkmn-checkbox {
  margin-top: 0;
}
.form-billing-info .paper-invoice .v-messages__message {
  color: $color-danger !important;
  font-weight: 700;
}
</style>
